<template>
  <div>
    <query-form
      ref="queryRef"
      :query-form.sync="queryRecordForm"
      :form="form"
    >
      <template v-slot:buttons>
        <b-button
          variant="primary"
          class="mr-1"
          @click="search"
        >Search</b-button>
        <b-button
          variant="secondary"
          class="mr-1"
          @click="reset"
        >Reset</b-button>
      </template>
    </query-form>
    <table-list
      :table-data.sync="tableData"
      :list.sync="tableList"
      :page-num.sync="currentPage"
      :total-list.sync="totalList"
    />
  </div>
</template>
<script>
import {
  defineComponent, ref, getCurrentInstance, watch,
} from '@vue/composition-api'
import { BButton } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default defineComponent({
  setup() {
    const { proxy } = getCurrentInstance()
    // 表单
    const queryRecordForm = ref({})
    const form = ref([
      {
        label: 'Search Terms',
        itype: 'modeinput',
        imodel: 'searchTerms',
        placeholder: 'search...',
        Options: [{ title: 'USER NAME', value: '7' }, { title: 'IP ADDRESS', value: '10' }],
        labelKey: 'title',
        labelValue: 'value',
        defaultMode: { title: 'USER NAME', value: '7' },
        md: 5,
      },
      {
        label: 'Time', itype: 'datetime', imodel: 'timeRangeDefault1', placeholder: '', md: 5,
      },
    ])
    // 列表
    const currentPage = ref(1)
    const tableList = ref([])
    const totalList = ref(0)
    const tableData = ref({
      tableColumns: [
        { key: 'created_at_ms', label: 'date & time' },
        { key: 'user_name', label: 'user Name' },
        { key: 'remark', label: 'remark' },
        { key: 'ip', label: 'ip' },
      ],
      templates: [
        { key: 'created_at_ms', tType: 'datetime' },
      ],
    })
    // watch([timeZone], value => { console.log('activity时区', value[0]); timeRange.value = [] })
    const reset = () => {
      proxy.$refs.queryRef.reset()
    }
    const getParams = () => {
      const newParams = {
        key: {
          model: 7,
          key: '',
        },
        time_scope: {
          begin: null,
          end: null,
        },
        page_size: 10,
        page_no: currentPage.value,
      }
      if (queryRecordForm.value.searchTerms) {
        newParams.key.model = Number(queryRecordForm.value.searchTerms.modeValue)
        newParams.key.key = queryRecordForm.value.searchTerms.searchKey.trim()
      }
      if (queryRecordForm.value.timeRangeDefault1 && queryRecordForm.value.timeRangeDefault1.length > 0) {
        [newParams.time_scope.begin, newParams.time_scope.end] = queryRecordForm.value.timeRangeDefault1
        newParams.time_scope.begin = `${newParams.time_scope.begin}`
        newParams.time_scope.end = `${newParams.time_scope.end}`
      }
      return newParams
    }
    const fetchActivityList = async () => {
      const newParams = getParams()
      proxy.$showLoading()
      const res = await proxy.$api.fetchActivityList(newParams)
      proxy.$hideLoading()
      const { code, data, message } = res.data
      if (code === 200) {
        const { pagination, list } = data
        tableList.value = list
        totalList.value = pagination.total_count
      } else {
        proxy.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            text: `${message}`,
            variant: 'danger',
          },
        }, {
          position: 'top-center',
        })
      }
    }
    const search = async () => {
      currentPage.value = 1
      await fetchActivityList()
    }
    watch([currentPage], async () => { await fetchActivityList() })
    return {
      queryRecordForm,
      tableData,
      tableList,
      currentPage,
      totalList,

      // func
      search,
      reset,
      form,
    }
  },
  components: {
    BButton,
    // BCard, BForm, BRow, BCol, BInputGroup, BFormInput, BButton, BTable, BPagination, BInputGroupPrepend, BDropdown, BDropdownItem, DatePicker,
  },
})
</script>
